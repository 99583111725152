import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as corApi from '../api/cor'
import { useSelector } from 'react-redux'

const Cor = () => {
  const translations = useSelector(state => state.translations.translations)
  const corsTranslations = translations['system-cor']

  const columns = [
    {
      title: corsTranslations['manage-title'],
      field: "nome",
    },
  ];

  return (
    <React.Fragment>
      <Header title={corsTranslations['title']} subtitle={corsTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={corApi.fetchItems}
            removeFunction={corApi.removeItem}
            statusFunction={corApi.statusItem}
            title={corsTranslations['title']}
            url={'cor'}
          />
        </BigCard>

      </Container>
    </React.Fragment>
  )
}

export default Cor
