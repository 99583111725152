import React, { useState, useEffect } from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as produtoApi from '../api/produto'
import { fetchItems as fetchFabricantes } from '../api/fabricante'
import { fetchItems as fetchCores } from '../api/cor'
import { fetchItems as fetchColecoes } from '../api/colecao'
import { fetchItems as fetchDesigners } from '../api/designer'
import { fetchItems as fetchEstampas } from '../api/estampa'
import { fetchItems as fetchCategorias } from '../api/categorias'
import { useSelector } from 'react-redux'

const EditProduto = ({ edit }) => {
    const translations = useSelector(state => state.translations.translations)
    const produtosTranslations = translations['system-produto']

    const [categorias, setCategorias] = useState([])
    const [colecoes, setColecoes] = useState([])
    const [cores, setCores] = useState([])
    const [designers, setDesigners] = useState([])
    const [fabricantes, setFabricantes] = useState([])

    useEffect(() => {
        fetchFabricantes({limit: 9999}).then(res => {
            let fabricanteOptions = []
            res.data.forEach(fabricante => {
                fabricanteOptions.push({ value: fabricante._id, label: fabricante.nome })
            })
            setFabricantes(fabricanteOptions)
        })

        fetchColecoes({limit: 9999}).then(res => {
            let itemOptions = []
            res.data.forEach(item => {
                itemOptions.push({ value: item._id, label: item.nome })
            })
            setColecoes(itemOptions)
        })

        fetchCores({limit: 9999}).then(res => {
            let itemOptions = []
            res.data.forEach(item => {
                itemOptions.push({ value: item._id, label: item.nome })
            })
            setCores(itemOptions)
        })
        fetchDesigners({limit: 9999}).then(res => {
            let itemOptions = []
            res.data.forEach(item => {
                itemOptions.push({ value: item._id, label: item.nome })
            })
            setDesigners(itemOptions)
        })
        fetchCategorias({limit: 9999}).then(res => {
            let itemOptions = []
            res.data.forEach(item => {
                itemOptions.push({ value: item._id, label: item.nome })
            })
            setCategorias(itemOptions)
        })

    }, [])

    const selectedData = [
        {
            name: '_id',
            type: 'hidden',
            title: 'Id'
        },
        {
            name: 'nome',
            type: 'text',
            title: produtosTranslations['edit-nome'],
            validation: {
                required: true
            }
        },
        {
            name: 'codigo',
            type: 'text',
            title: produtosTranslations['edit-codigo'],
            validation: {
                required: true
            }
        },
        {
            name: 'descricao',
            type: 'tiny',
            title: produtosTranslations['edit-descricao'],
        },
        {
            name: 'observacao',
            type: 'textarea',
            title: produtosTranslations['edit-observacao'],
        },
        {
            name: 'colecao',
            type: 'select',
            title: produtosTranslations['edit-colecao'],
            selectOptions: colecoes,
            options: {
                populated: true
            }
        },
        {
            name: 'categoria',
            type: 'select',
            title: produtosTranslations['edit-categoria'],
            selectOptions: categorias,
            options: {
                populated: true
            },
        },
        {
            name: 'cor',
            type: 'select',
            title: produtosTranslations['edit-cor'],
            selectOptions: cores,
            options: {
                populated: true
            }
        },
        {
            name: 'designer',
            type: 'select',
            title: produtosTranslations['edit-designer'],
            selectOptions: designers,
            options: {
                populated: true
            }
        },
        {
            name: 'fabricante',
            type: 'select',
            title: produtosTranslations['edit-fabricante'],
            selectOptions: fabricantes,
            options: {
                populated: true
            }
        },
        {
            name: 'galeria',
            type: 'dragdrop',
            title: produtosTranslations['edit-galeria'],
            options: {
                customRemoveImgFromArray: (id, imgid) => produtoApi.removeImgGaleria(id, imgid)
            }
        },
    ]
    
    return (
        <EditPage
            title={edit ? produtosTranslations['editpage-title'] : produtosTranslations['addpage-title']}
            subtitle={edit ? produtosTranslations['editpage-subtitle'] : produtosTranslations['addpage-subtitle']}
            selectedData={selectedData}
            addFunction={produtoApi.addItem}
            fetchFunction={produtoApi.fetchItem}
            editFunction={produtoApi.editItem}
            uploadFunction={produtoApi.uploadProduto}
            edit={edit}
            url='produto'
        />
    )
}

export default EditProduto
