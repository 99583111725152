import React from 'react';

const Pedido = ({ pedido }) => {
    return (
        <tr>
            <td><img style={{maxWidth: "3rem"}} src={process.env.REACT_APP_API_URL + 'produto/' + pedido.produto._id + "/thumb"} alt="" srcset="" /></td>
            <td>{pedido.produto.codigo}</td>
            <td>{pedido.produto.nome}</td>
            <td>{pedido.tamanho.tamanho}</td>
            <td>{pedido.quantidade}</td>
            <td>{(parseFloat(pedido.promo_ativada ? pedido.tamanho.promo_valor : pedido.tamanho.valor) * pedido.quantidade).toFixed(2)}</td>
        </tr>);
};

export default Pedido;
