import React from 'react'
import { useSelector } from 'react-redux';
import ViewPedidos from '../components/ViewPedido';
import * as pedidosApi from '../api/pedido'

const ViewPedidosPage = ({ edit }) => {
  const translations = useSelector(state => state.translations.translations)
  const pedidosTranslations = translations['system-pedidos']

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
        name: 'pedidoId',
        type: 'text',
        title: pedidosTranslations['edit-id']
      },
    {
      name: 'nome',
      type: 'text',
      title: pedidosTranslations['edit-nome']
    },
    {
      name: 'email',
      type: 'text',
      title: pedidosTranslations['edit-email']
    },
    {
      name: 'telefone',
      type: 'text',
      title: pedidosTranslations['edit-telefone']
    },
    {
      name: 'itens',
      type: 'pedidos',
      title: pedidosTranslations['edit-pedidos']
    },
  ]
  return (
    <ViewPedidos
      title={edit ? pedidosTranslations['editpage-title'] : pedidosTranslations['addpage-title']}
      subtitle={edit ? pedidosTranslations['editpage-subtitle'] : pedidosTranslations['addpage-subtitle']}
      selectedData={selectedData}
      fetchFunction={pedidosApi.fetchItem}
      url='pedidos'
    />
  )
}

export default ViewPedidosPage