import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as designersApi from '../api/designer'
import { useSelector } from 'react-redux'

const Designer = () => {
  const translations = useSelector(state => state.translations.translations)
  const designersTranslations = translations['system-designer']

  const columns = [
    {
      title: designersTranslations['manage-title'],
      field: "nome",
    },
  ];

  return (
    <React.Fragment>
      <Header title={designersTranslations['title']} subtitle={designersTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={designersApi.fetchItems}
            removeFunction={designersApi.removeItem}
            statusFunction={designersApi.statusItem}
            title={designersTranslations['title']}
            url={'designer'}
          />
        </BigCard>

      </Container>
    </React.Fragment>
  )
}

export default Designer
