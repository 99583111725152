import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
// import { Tabela } from '../components/Tabela'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as produtosApi from '../api/produto'
import { useSelector } from 'react-redux'
import { MdAttachMoney } from 'react-icons/md'
import { Link } from 'react-router-dom'
import styles from '../styles/TabelaProdutos.module.scss'
import * as categoriasApi from '../api/categorias'

const Produto = () => {
  const translations = useSelector(state => state.translations.translations)
  const produtosTranslations = translations['system-produto']

  const [categorias, setCategorias] = useState([]);
  const [searchBy, setSearchBy] = useState({});

  useEffect(() => {
    categoriasApi.fetchItems({ limit: 100 }).then(res => {
      let categoriasOptions = []
      res.data.forEach(categoria => {
        categoriasOptions.push({
          value: categoria._id,
          label: categoria.nome
        })
      })
      setCategorias(categoriasOptions)
      setSearchBy({ categoria: { options: categoriasOptions } })
    })
  }, [])

  const columns = [
    {
      title: produtosTranslations['manage-imagem'],
      field: "galeria",
      render: (row) => (
        <img src={process.env.REACT_APP_API_URL + "produto/" + row._id + '/thumb'} alt="" srcSet="" />
      )
    },
    {
      title: produtosTranslations['manage-title'],
      field: "nome",
      filtering: true,
    },
    {
      title: produtosTranslations['manage-codigo'],
      field: "codigo",
      filtering: true,
    },
    {
      title: 'categoria',
      filtering: true,
      field: "categoria",
      sorting: categorias,
      render: (rowData) => <span>{categorias.length > 0 && categorias.find(categoria => categoria.value == rowData.categoria).label}</span>
    },
    {
      title: produtosTranslations['manage-valores'],
      field: "valores",
      center: true,
      render: (rowData) =>
        <div className={styles.valores_link}>
          <Link to={"/valor/" + rowData._id}>
            <MdAttachMoney size="1.5rem" />
          </Link>
        </div>
    },
  ];

  return (
    <React.Fragment>
      <Header title={produtosTranslations['title']} subtitle={produtosTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={produtosApi.fetchItems}
            removeFunction={produtosApi.removeItem}
            statusFunction={produtosApi.statusItem}
            title={produtosTranslations['title']}
            enableSearch={true}
            searchBy={searchBy}
            url={'produto'}

          />
        </BigCard>

      </Container>
    </React.Fragment>
  )
}

export default Produto
