import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as fabricantesApi from '../api/fabricante'
import { useSelector } from 'react-redux'

const Fabricante = () => {
  const translations = useSelector(state => state.translations.translations)
  const fabricantesTranslations = translations['system-fabricante']

  const columns = [
    {
      title: fabricantesTranslations['manage-title'],
      field: "nome",
    },
  ];

  return (
    <React.Fragment>
      <Header title={fabricantesTranslations['title']} subtitle={fabricantesTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={fabricantesApi.fetchItems}
            removeFunction={fabricantesApi.removeItem}
            statusFunction={fabricantesApi.statusItem}
            title={fabricantesTranslations['title']}
            url={'fabricante'}
          />
        </BigCard>

      </Container>
    </React.Fragment>
  )
}

export default Fabricante
