import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as pageApi from '../api/pages'
import { useSelector } from 'react-redux'

const Pages = () => {
  const translations = useSelector(state => state.translations.translations)
  const cassinosTranslations = translations['system-pages']

  const columns = [
    {
      title: cassinosTranslations['manage-title'],
      field: "title",
    },
  ];

  return (
    <React.Fragment>
      <Header title={cassinosTranslations['title']} subtitle={cassinosTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={pageApi.fetchItems}
            removeFunction={pageApi.removeItem}
            statusFunction={pageApi.statusItem}
            title={cassinosTranslations['title']}
            url={'pages'}
          />
        </BigCard>

      </Container>


    </React.Fragment>
  )
}

export default Pages
