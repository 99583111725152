import React from 'react'
import { useSelector } from 'react-redux'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { MdInfo } from 'react-icons/md'

const FormField = React.forwardRef(({ name, title, subtitle, required, valid, feedback, tooltip, hasFeedback, children }, ref) => {
  const translations = useSelector(state => state.translations.translations.formhelper)
  return (
    <Form.Group controlId={name} ref={ref}>
      <Form.Label className="title-control">
        {title}{required ? '*' : ''}
        {tooltip &&
          <OverlayTrigger
            placement={tooltip.placement || 'top'}
            overlay={
              <Tooltip id={`tooltip-${name}`}>
                {tooltip.text}
              </Tooltip>
            }
          >
            <MdInfo size="1.25em" />
          </OverlayTrigger>}
      </Form.Label>
      {children}
      {(subtitle && subtitle !== '') &&
        <Form.Text className="text-muted">
          {subtitle}
        </Form.Text>}
      { hasFeedback ?
          <Form.Control.Feedback type="invalid">
            {feedback ? title + feedback : translations.required}
          </Form.Control.Feedback>
        :
        <>
          {(valid !== undefined && !valid) &&
            <div className="invalid">
              {feedback ? feedback : translations.required}
            </div>
          }
        </>
      }
    </Form.Group>
  )
})

export default FormField