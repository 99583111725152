import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as estampasApi from '../api/estampa'
import { useSelector } from 'react-redux'

const Estampa = () => {
  const translations = useSelector(state => state.translations.translations)
  const estampasTranslations = translations['system-estampa']

  const columns = [
    {
      title: estampasTranslations['manage-title'],
      field: "nome",
    },
  ];

  return (
    <React.Fragment>
      <Header title={estampasTranslations['title']} subtitle={estampasTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={estampasApi.fetchItems}
            removeFunction={estampasApi.removeItem}
            statusFunction={estampasApi.statusItem}
            title={estampasTranslations['title']}
            url={'estampa'}
          />
        </BigCard>

      </Container>
    </React.Fragment>
  )
}

export default Estampa
