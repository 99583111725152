import React from 'react'
import { Header } from '@equipedigitalfloripa/ed-cms/components'

const NotFound = () => {
  return (
    <div>
      <Header title="Not found 404">

      </Header>
    </div>
  )
}

export default NotFound
