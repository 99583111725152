import React from 'react';
import { Table } from 'react-bootstrap';
import Pedido from './Pedido';

const Pedidos = ({ pedidos }) => {
    return (
        <Table responsive="md">
            <thead>
                <tr>
                    <th>Imagem</th>
                    <th>Código</th>
                    <th>Nome</th>
                    <th>Tamanho</th>
                    <th>Quantidade</th>
                    <th>Preço</th>
                </tr>
            </thead>
            <tbody>
                {
                    pedidos.length > 0 &&
                    pedidos.map(pedido => <Pedido key={Math.random()} pedido={pedido}/>)
                }
                
            </tbody>
        </Table>
    );
};

export default Pedidos;
