import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { BigCard, Header } from '@equipedigitalfloripa/ed-cms/components'
import FormField from '../components/FormField';
import ReactLoading from 'react-loading';
import { MdContactMail } from 'react-icons/md';
import Pedidos from './Pedidos';

const ViewPedidos = ({ full_url, selectedData, fetchFunction, url, title, subtitle }) => {
  const [data, setData] = useState({})
  const translations = useSelector(state => state.translations.translations['system-pedidos'])
  const { id } = useParams()
  //Inicialização da página de edição
  useEffect(() => {
    //Puxa infos do objeto da API a partir do ID na url
    fetchFunction(id).then((response) => {

      let allData = response.data
      let filteredData = {}

      //Seleciona apenas os dados que foram setados na configuração desta página
      //e salva no estado "data"
      selectedData.forEach((value) => {
        filteredData[value.name] = allData[value.name]
      })
      setData(filteredData)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Header
        title={title}
        subtitle={subtitle}
      >
        <MdContactMail />
      </Header>
      <Container className="page-content">
        <BigCard>
          {
            //Não renderiza página até finalizar a chamada de objetos do backend em caso de edição.
            //Evita que campos como TinyMCE e Multiselect sejam inicializados sem valores iniciais
            !data._id ?
              <div style={{ width: '4em', marginLeft: 'auto', marginRight: 'auto', display: 'flex', justifyContent: 'center' }}>
                <ReactLoading color="#0586D7" type="spin" />
              </div>
              :
              <Form noValidate onSubmit={(e) => e.preventDefault()} autoComplete="off">
                {selectedData.map(({ name, title, type }) => {
                  switch (type) {
                    case 'text':
                      return (
                        <div key={name}>
                          <FormField

                            name={name}
                            title={title}
                          >
                            <Form.Control
                              defaultValue={data[name] ? data[name] : ''}
                              plaintext
                              readOnly
                            />
                          </FormField>
                          <hr />
                        </div>
                      )
                    case 'textarea':
                      return (
                        <FormField
                          key={name}
                          name={name}
                          title={title}
                        >
                          <Form.Control
                            defaultValue={data[name] ? data[name] : ''}
                            plaintext
                            readOnly
                            as="textarea"
                          />
                        </FormField>
                      )
                    case 'pedidos':
                      return (
                        <FormField
                          key={name}
                          name={name}
                          title={title}
                        >
                          <Pedidos pedidos={data[name] ? data[name] : []}/>
                        </FormField>
                      )
                    default:
                      return false
                  }
                })}
                <Row className="confirm">
                  <Col xs="auto">
                    <Link to={"/" + url}>
                      <Button className="confirm__button" variant="secondary">
                        {translations.back}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Form>
          }
        </BigCard>
      </Container>
    </>
  )
}
export default ViewPedidos