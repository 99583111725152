import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { startCheck } from '@equipedigitalfloripa/ed-cms/actions'
import { startFetchLang, startFetchTranslations } from '@equipedigitalfloripa/ed-cms/actions'
import { LoginRoute } from '@equipedigitalfloripa/ed-cms/routes'
import ReactLoading from 'react-loading';
import { LoginPage, ForgotPage, PassReset } from '@equipedigitalfloripa/ed-cms/pages'
import SystemRouter from './SystemRouter'
import { useSelector } from 'react-redux'
import '@equipedigitalfloripa/ed-cms/components/main.css'
import '@equipedigitalfloripa/ed-cms/pages/main.css'

const AppRouter = () => {
  const translations = useSelector(state => state.translations.translations)
  const dispatch = useDispatch()
  const [failed, setFailed] = useState(false)

  useEffect(() => {

    const fetchData = async () => {
      try {
        await dispatch(startCheck(process.env.REACT_APP_API_URL))
        await dispatch(startFetchLang())
        await dispatch(startFetchTranslations(process.env.REACT_APP_API_URL))
      }
      catch(e){
        setFailed(true)
      }
      
    }

    fetchData()
    // eslint-disable-next-line
  }, [])
  return (
    <React.Fragment>
      {!!translations ?
        <Router>
          <Switch>
            <LoginRoute path="/login" component={LoginPage} exact />
            <LoginRoute path="/iforgot" component={ForgotPage} exact />
            <LoginRoute path="/reset" component={PassReset} exact />
            <Route path="*" component={SystemRouter} />
          </Switch>
        </Router>
        :
        <div className={`pageLoading ${!!translations ? 'fade' : ''}`}>
          {failed ?
          <p style={{padding: 10}}>Error connecting to the server, please contact support</p>
          :<ReactLoading width="4em" height="4em" color="#0586D7" type="spin"/>}
        </div>
      }
    </React.Fragment>
  )
}

export default AppRouter
