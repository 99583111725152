import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import { MdFileDownload } from "react-icons/md";
import * as arquivosApi from '../api/arquivos'
import { useSelector } from 'react-redux'

const Arquivo = () => {
  const translations = useSelector(state => state.translations.translations)
  const arquivosTranslations = translations['system-arquivos']

  const columns = [
    {
      title: arquivosTranslations['manage-title'],
      field: "title",
    },
    {
      title: arquivosTranslations['editpage-title'],
      field: "file",
      filtering: false,
      sorting: false,
      center: true,
      render: rowData => <a href={process.env.REACT_APP_API_URL + "arquivos/" + rowData._id + '/file'} target="_blank" rel="noreferrer" className="fileDownload"><MdFileDownload size="2em" /></a>
    }
  ];

  return (
    <>
      <Header title={arquivosTranslations['title']} subtitle={arquivosTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={arquivosApi.fetchItems}
            removeFunction={arquivosApi.removeItem}
            statusFunction={arquivosApi.statusItem}
            title={arquivosTranslations['title']}
            url={'arquivos'}
          />
        </BigCard>

      </Container>
    </>
  )
}

export default Arquivo
