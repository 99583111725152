import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import { MainLayout } from '@equipedigitalfloripa/ed-cms/layouts'

import IndexPage from '../pages/Index'
import NotFoundPage from '../pages/NotFound'

import ContentsPage from '../pages/Contents'
import EditContentPage from '../pages/EditContent'

import NoticiasPage from '../pages/Noticias'
import EditNoticiasPage from '../pages/EditNoticia'

import ArquivosPage from '../pages/Arquivos'
import EditArquivosPage from '../pages/EditArquivo'

import DesignerPage from '../pages/Designer'
import EditDesignerPage from '../pages/EditDesigner'

import ColecaoPage from '../pages/Colecao'
import EditColecaoPage from '../pages/EditColecao'

import CorPage from '../pages/Cor'
import EditCorPage from '../pages/EditCor'

import EstampaPage from '../pages/Estampa'
import EditEstampaPage from '../pages/EditEstampa'

import FabricantePage from '../pages/Fabricante'
import EditFabricantePage from '../pages/EditFabricante'

import ProdutoPage from '../pages/Produto'
import EditProdutoPage from '../pages/EditProduto'

import ValorPage from '../pages/Valor'
import EditValorPage from '../pages/EditValor'

import SliderPage from '../pages/Slider'
import EditSliderPage from '../pages/EditSlider'

import CategoriasPage from '../pages/Categorias'
import EditCategoriasPage from '../pages/EditCategorias'

import PagesPage from '../pages/Pages'
import EditPagesPage from '../pages/EditPages'

import PedidosPage from '../pages/Pedidos'
import ViewPedidosPage from '../pages/ViewPedidoPage'

import {
  UsersPage,
  EditUserPage,
  EditMetatagsPage,
  EditConfigPage,
  EditSocialMidiaPage,
  TranslationsPage,
  EditTranslationsPage,
  Contatos,
  ViewContatosPage
} from '@equipedigitalfloripa/ed-cms/pages'

import { startSetLang, startFetchTranslations } from '@equipedigitalfloripa/ed-cms/actions'

import { useSelector } from 'react-redux'

import '@equipedigitalfloripa/ed-cms/layouts/main.css'
import '@equipedigitalfloripa/ed-cms/pages/main.css'
import '@equipedigitalfloripa/ed-cms/components/main.css'

const SystemRouter = () => {
  const dispatch = useDispatch()
  const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:3000/'
  const full_url = url + 'metatags/'
  const full_url_config = url + 'config/'
  const full_url_socialmidia = url + 'socialmidia/'
  const { loggedIn, user } = useSelector(state => state.auth)
  const translations = useSelector(state => state.translations.translations.sidebar)

  useEffect(() => {
    if (user && user.preferences) {
      dispatch(startSetLang(user.preferences.lang))
      dispatch(startFetchTranslations(process.env.REACT_APP_API_URL))

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const navigation = [
    // {
    //   title: translations.site_news,
    //   link: [
    //     {
    //       link: '/noticias',
    //       title: translations.site_news
    //     },

    //   ]
    // },
    {
      link: '/pages',
      title: translations.site_pages
    },
    {
      link: '/contents',
      title: translations.site_simple_content
    },
    {
      link: '/pedidos',
      title: translations.site_pedidos
    },
    {
      title: translations.produtos,
      link: [
        {
          link: '/produto',
          title: translations.produtos
        },
        {
          link: '/categorias',
          title: translations.site_categories
        },
        {
          link: '/colecao',
          title: translations.estampas
        },
        {
          link: '/cor',
          title: translations.cores
        },
        {
          link: '/designer',
          title: translations.designer
        },
        {
          link: '/fabricante',
          title: translations.fabricantes
        },
      ]
    },

    {
      link: '/slider',
      title: translations.slider
    },
  ]
  return (loggedIn ?
    <Router>
      <MainLayout navigation={navigation}>
        <Switch>
          <Route path="/" component={IndexPage} exact />

          <Route
            path="/edit/pages/:id"
            render={(props) => (
              <EditPagesPage {...props} edit={true} />
            )}
          />
          <Route path="/add/pages" component={EditPagesPage} />
          <Route path="/pages" component={PagesPage} />

          <Route
            path="/edit/noticias/:id"
            render={(props) => (
              <EditNoticiasPage {...props} edit={true} />
            )}
          />
          <Route path="/add/noticias" component={EditNoticiasPage} />
          <Route path="/noticias" component={NoticiasPage} />

          <Route
            path="/edit/contents/:id"
            render={(props) => (
              <EditContentPage {...props} edit={true} />
            )}
          />
          <Route path="/add/contents" component={EditContentPage} />
          <Route path="/contents" component={ContentsPage} />

          <Route
            path="/edit/arquivos/:id"
            render={(props) => (
              <EditArquivosPage {...props} edit={true} />
            )}
          />
          <Route path="/add/arquivos" component={EditArquivosPage} />
          <Route path="/arquivos" component={ArquivosPage} />

          <Route
            path="/edit/designer/:id"
            render={(props) => (
              <EditDesignerPage {...props} edit={true} />
            )}
          />
          <Route path="/add/designer" component={EditDesignerPage} />
          <Route path="/designer" component={DesignerPage} />

          <Route
            path="/edit/estampa/:id"
            render={(props) => (
              <EditEstampaPage {...props} edit={true} />
            )}
          />
          <Route path="/add/estampa" component={EditEstampaPage} />
          <Route path="/estampa" component={EstampaPage} />

          <Route
            path="/edit/fabricante/:id"
            render={(props) => (
              <EditFabricantePage {...props} edit={true} />
            )}
          />
          <Route path="/add/fabricante" component={EditFabricantePage} />
          <Route path="/fabricante" component={FabricantePage} />

          <Route
            path="/edit/valor/:id/:id2"
            render={(props) => (
              <EditValorPage {...props} edit={true} />
            )}
          />
          <Route path="/add/valor/:id" component={EditValorPage} />
          <Route path="/valor/:id" component={ValorPage} />
          
          <Route
            path="/edit/produto/:id"
            render={(props) => (
              <EditProdutoPage {...props} edit={true} />
            )}
          />
          <Route path="/add/produto" component={EditProdutoPage} />
          <Route path="/produto" component={ProdutoPage} />

          <Route
            path="/edit/cor/:id"
            render={(props) => (
              <EditCorPage {...props} edit={true} />
            )}
          />
          <Route path="/add/cor" component={EditCorPage} />
          <Route path="/cor" component={CorPage} />



          <Route
            path="/edit/colecao/:id"
            render={(props) => (
              <EditColecaoPage {...props} edit={true} />
            )}
          />
          <Route path="/add/colecao" component={EditColecaoPage} />
          <Route path="/colecao" component={ColecaoPage} />

          <Route
            path="/edit/slider/:id"
            render={(props) => (
              <EditSliderPage {...props} edit={true} />
            )}
          />
          <Route path="/add/slider" component={EditSliderPage} />
          <Route path="/slider" component={SliderPage} />

          <Route
            path="/edit/categorias/:id"
            render={(props) => (
              <EditCategoriasPage {...props} edit={true} />
            )}
          />
          <Route path="/add/categorias" component={EditCategoriasPage} />
          <Route path="/categorias" component={CategoriasPage} />

          <Route
            path="/edit/pedidos/:id"
            render={(props) => (
              <ViewPedidosPage {...props} edit={true} />
            )}
          />
          <Route path="/pedidos" component={PedidosPage} />

          <Route path="/edit/metatags">
            <EditMetatagsPage full_url={full_url} />
          </Route>
          <Route path="/edit/config">
            <EditConfigPage full_url={full_url_config} />
          </Route>
          <Route path="/edit/socialmidia">
            <EditSocialMidiaPage full_url={full_url_socialmidia} />
          </Route>

          <Route path="/edit/users/:id">
            <EditUserPage api_url={url} edit={true} />
          </Route>
          <Route path="/add/users">
            <EditUserPage api_url={url} />
          </Route>
          <Route path="/users">
            <UsersPage api_url={url} />
          </Route>

          <Route path="/edit/contatos/:id">
            <ViewContatosPage api_url={url} edit={true} />
          </Route>
          <Route path="/contatos">
            <Contatos api_url={url} />
          </Route>

          <Route path="/edit/translations/:id" >
            <EditTranslationsPage api_url={url} />
          </Route>
          <Route path="/translations">
            <TranslationsPage api_url={url} />
          </Route>
          <Route component={NotFoundPage} />
        </Switch>
      </MainLayout>
    </Router>
    : <Redirect to='/login' />)
}

export default SystemRouter
