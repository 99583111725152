import React, { useEffect, useState } from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
// import { EditPage } from '../components/EditPage'
import * as noticiaApi from '../api/noticias'
import { fetchItems } from '../api/categorias'
import { useSelector } from 'react-redux'

const EditNoticia = ({ edit }) => {
  const translations = useSelector(state => state.translations.translations)
  const noticiasTranslations = translations['system-noticias']

  const [categorias, setCategorias] = useState([])

  useEffect(() => {
    fetchItems().then(res => {
      let categoriasOptions = []
      res.data.forEach(categoria => {
        categoriasOptions.push({ value: categoria._id, label: categoria.nome })
      })
      setCategorias(categoriasOptions)
    })
  }, [])

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'titulo',
      type: 'text',
      title: noticiasTranslations['edit-title'],
      validation: {
        required: true
      },
      options: {
        maxLength: '250'
      },
      tooltip: {
        text: noticiasTranslations['edit-title-p']
      }
    },
    {
      name: 'subtitulo',
      type: 'text',
      title: noticiasTranslations['edit-subtitulo'],
      options: {
        optional: true
      }
    },
    {
      name: 'categoria',
      type: 'select',
      title: noticiasTranslations['edit-categoria'],
      selectOptions: categorias,
      options: {
        populated: true
      }
    },
    {
      name: 'data_noticia',
      type: 'date',
      options:{
        formatHora: "Pp"
      },
      title: noticiasTranslations['edit-datanoticia']
    },
    {
      name: 'data_publicacao',
      type: 'date',
      options:{
        formatHora: "Pp",
        optional: true
      },
      title: noticiasTranslations['edit-datapublicacao'],
      subtitle: noticiasTranslations['edit-datapublicacao-s'],
    },
    {
      name: 'destacar',
      type: 'checkbox',
      title: noticiasTranslations['edit-destacar'],
      subtitle: noticiasTranslations['edit-destacar-s']
    },
    {
      name: 'corpo',
      type: 'tiny',
      title: noticiasTranslations['edit-corpo'],
      conditional: [
        {
          name: 'categoria',
          nequal: "61201ccf67375f2f903d4790"
        }
      ],
      options: {
        optional: true
      }
    },
    {
      name: 'video',
      type: 'text',
      title: noticiasTranslations['edit-link'],
      conditional: [
        {
          name: 'categoria',
          equal: "61201ccf67375f2f903d4790"
        }
      ],
    },
    {
      name: 'img',
      type: 'dragdrop',
      title: noticiasTranslations['edit-image'],
      options: {
        maxFiles: 1
      },
      validation: {
        required: true
      },
      conditional: [
        // { /** VIDEO NECESSITA DE UMA THUMBNAIL */
        //   name: 'categoria',
        //   nequal: "61201ccf67375f2f903d4790" 
        // },
        {
          name: 'categoria',
          nequal: "60b7b815cb748f4d8447b8b1"
        }
      ],
    },
    {
      name: 'galeria',
      type: 'dragdrop',
      title: noticiasTranslations['edit-galeria'],
      conditional: [
        {
          name: 'categoria',
          equal: "60b7b815cb748f4d8447b8b1"
        }
      ],
      options: {
        customRemoveImgFromArray: (id, imgid) => noticiaApi.removeImgGaleria(id, imgid)
      }
    },
    {
      name: 'url',
      type: 'text',
      title: noticiasTranslations['edit-url'],
      subtitle: noticiasTranslations['edit-url-s'],
      validation: {
        required: true
      },
      tooltip: {
        text: noticiasTranslations['edit-url-p']
      }
    }
  ]
  return (
    <EditPage
      title={edit ? noticiasTranslations['editpage-title'] : noticiasTranslations['addpage-title']}
      subtitle={edit ? noticiasTranslations['editpage-subtitle'] : noticiasTranslations['addpage-subtitle']}
      selectedData={selectedData} 
      addFunction={noticiaApi.addItem}
      fetchFunction={noticiaApi.fetchItem}
      editFunction={noticiaApi.editItem}
      uploadFunction={noticiaApi.uploadNoticia}
      edit={edit}
      url='noticias'
    />
  )
}

export default EditNoticia
