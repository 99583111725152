import React from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as sliderApi from '../api/slider'
import { useSelector } from 'react-redux'

const EditSlider = ({ edit }) => {
  const translations = useSelector(state => state.translations.translations)
  const sliderTranslations = translations['system-slider']

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'title',
      type: 'text',
      title: sliderTranslations['edit-title'],
      validation: {
        required: true
      }
    },
    {
      name: 'slider_background',
      type: 'dragdrop',
      title: sliderTranslations['edit-image-background'],
      validation: {
        required: true
      },
      tooltip:{
        text: sliderTranslations['edit-image-background-t']
      }
    },
    // {
    //   name: 'slider_text',
    //   type: 'dragdrop',
    //   title: sliderTranslations['edit-image-text'],
    //   tooltip:{
    //     text: sliderTranslations['edit-image-text-t']
    //   }
    // }
  ]
  return (
    <EditPage
      title={edit ? sliderTranslations['editpage-title'] : sliderTranslations['addpage-title']}
      subtitle={edit ? sliderTranslations['editpage-subtitle'] : sliderTranslations['addpage-subtitle']}
      selectedData={selectedData}
      addFunction={sliderApi.addItem}
      fetchFunction={sliderApi.fetchItem}
      uploadFunction={sliderApi.uploadSlider}
      editFunction={sliderApi.editItem}
      edit={edit}
      url='slider' />
  )
}

export default EditSlider
