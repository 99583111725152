import React from 'react'
import { useParams } from "react-router-dom";
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as valorApi from '../api/valor'
import { useSelector } from 'react-redux'

const EditValor = ({ edit }) => {
    const translations = useSelector(state => state.translations.translations)
    const valorsTranslations = translations['system-valor']
    let { id } = useParams();
    const selectedData = [
        {
            name: '_id',
            type: 'hidden',
            title: 'Id'
        },
        {
            name: 'tamanho',
            type: 'text',
            title: valorsTranslations['edit-tamanho'],
            validation: {
                required: true
            }
        },
        {
            name: 'valor',
            type: 'text',
            title: valorsTranslations['edit-valor'],
            validation: {
                required: true
            }
        },
        {
            name: 'promo_ativada',
            type: 'checkbox',
            title: valorsTranslations['edit-promo_ativada'],
        },
        {
            name: 'promo_valor',
            type: 'text',
            title: valorsTranslations['edit-promo_valor'],
        },
    ]
    const addItem = (item) => valorApi.addItem(id,item)
    const fetchItem = (id2) => valorApi.fetchItem(id,id2)
    const editItem = (id2,item) => valorApi.editItem(id,id2,item)

    return (
        <EditPage
            selectedData={selectedData}
            addFunction={addItem}
            fetchFunction={fetchItem}
            editFunction={editItem}
            edit={edit}
            url={'valor/'+ id}
        />
    )
}

export default EditValor
