import axios from 'axios';

//Este arquivo funciona como um middleware para todos os Requests da página
//ele intercepta e trata a resposta

axios.interceptors.response.use(function (response) {
  
  //Caso não haja erro, retorna a resposta para o tratamento normal do Request
  return response
  }, function (error) {
    // Caso seja um erro de autorização, força um recarregamento da página
    if (error.response && error.response.status === 401) {
      window.location = "/login"
    }
    return Promise.reject(error);
  });

export default axios;