import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as colecaosApi from '../api/colecao'
import { useSelector } from 'react-redux'

const Colecao = () => {
  const translations = useSelector(state => state.translations.translations)
  const colecaosTranslations = translations['system-colecao']

  const columns = [
    {
      title: colecaosTranslations['manage-title'],
      field: "nome",
    },
  ];

  return (
    <React.Fragment>
      <Header title={colecaosTranslations['title']} subtitle={colecaosTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={colecaosApi.fetchItems}
            removeFunction={colecaosApi.removeItem}
            statusFunction={colecaosApi.statusItem}
            title={colecaosTranslations['title']}
            url={'colecao'}
          />
        </BigCard>

      </Container>
    </React.Fragment>
  )
}

export default Colecao
