import React from 'react'
import { useParams } from "react-router-dom";
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as valorApi from '../api/valor'
import { useSelector } from 'react-redux'

const Valor = () => {
  const translations = useSelector(state => state.translations.translations)
  const valorsTranslations = translations['system-valor']
  let { id } = useParams();
  const columns = [
    {
      title: valorsTranslations['manage-tamanho'],
      field: "tamanho",
    },
    {
      title: valorsTranslations['manage-valor'],
      field: "valor",
    },
    {
      title: valorsTranslations['manage-promo_ativada'],
      field: "promo_ativada",
      render: rowData =>
        <div>{rowData.promo_ativada ? 'Sim' : 'Não'}</div>
    },
    

  ];
  const fetchItems = (query) => valorApi.fetchItems(id, query)
  const removeItem = (id2) => valorApi.removeItem(id, id2)

  return (
    <React.Fragment>
      <Header title={valorsTranslations['title']} subtitle={valorsTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={fetchItems}
            removeFunction={removeItem}
            statusFunction={valorApi.statusItem}
            title={valorsTranslations['title']}
            url={'valor/' + id}
            disableStatus
          />
        </BigCard>

      </Container>
    </React.Fragment>
  )
}

export default Valor
